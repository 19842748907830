import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"

import {
  Button,
  Cover,
  ExternalStyledLink,
  InternalStyledLink,
  Section,
} from "../components"
import icnRelationships from "../images/home/icn-relationships.svg"
import icnIncome from "../images/home/icn-income.svg"
import icnJobs from "../images/home/icn-jobs.svg"
import icnCommunity from "../images/home/icn-community.svg"

const IndexPage = () => {
  return (
    <Wrapper>
      <Cover className="banner">
        <div className="textContainer shadowContainer">
          <div className="content">
            <h1>Innovating energy</h1>
            <p className="font-style-3">
              Leading South African developer in renewable energy
            </p>
            <Button
              label="Contact us"
              onClick={() => {
                navigate("/contact")
              }}
            />
          </div>
        </div>
        <div className="imageContainer">
          <StaticImage src="../images/home/img-home-placeholder.png" alt="" />
        </div>
      </Cover>
      <Section className="progress">
        <div className="teamStatement font-style-4">
          We are a team of highly skilled and well-respected professionals
          taking projects from scoping phase to successful completion.
        </div>
        <div className="stats">
          <div className="developed">
            <div className="imageContainer">
              <StaticImage
                src="../images/home/img-home-projects-developed.png"
                className="progressImg"
                alt=""
              />
            </div>
            <div className="textContainer">
              <h2 className="font-style-6 projectType">Developed Projects</h2>
              <p className="font-style-2">
                <span className="stat">Total MW</span>
                <span className="value">191</span>
              </p>
              <p className="description">Megawatts of Installed Wind Power</p>
              <ExternalStyledLink
                label="Kouga Wind Farm"
                href="http://kougawindfarm.co.za"
                size="none"
              />
              <span className="noLinkProject">Gibson Bay</span>
            </div>
          </div>
          <div className="inDevelopment">
            <div className="imageContainer">
              <StaticImage
                src="../images/home/img-home-projects-developing.png"
                className="progressImg"
                alt=""
              />
            </div>
            <div className="textContainer">
              <h2 className="font-style-6 projectType">
                Projects in Development
              </h2>
              <p className="font-style-2">
                <span className="stat">Total GW</span>
                <span className="value">1.5</span>
              </p>
              <p className="description">
                Gigawatts of wind power currently in various stages of
                development
              </p>
            </div>
          </div>
        </div>
      </Section>
      <Section dark className="landowners">
        <div>
          <h2>Landowners</h2>
          <p>
            There are many benefits to landowners leasing their land for wind
            energy production – it enables farmers to diversify the use of their
            land, providing additional financial security.
          </p>
          <InternalStyledLink label="Learn more" href="/landowners" />
        </div>
        <div className="commitments">
          <div>
            <img src={icnRelationships} alt="Relationships icon" />
            <h3 className="font-style-5">Relationships</h3>
            <p>We develop long-standing relationships with our landowners.</p>
          </div>
          <div>
            <img src={icnIncome} alt="Income icon" />
            <h3 className="font-style-5">Income</h3>
            <p>Our projects supply income for the landowners.</p>
          </div>
          <div>
            <img src={icnJobs} alt="Jobs icon" />
            <h3 className="font-style-5">Jobs</h3>
            <p>Our projects provide jobs in the area.</p>
          </div>
          <div>
            <img src={icnCommunity} alt="Community upliftment icon" />
            <h3 className="font-style-5">Community Upliftment</h3>
            <p>
              Our projects enable investments into social economic development.
            </p>
          </div>
        </div>
      </Section>
      <Section className="projects">
        <div className="textContainer">
          <h2>Projects</h2>
          <p>
            “We have a highly skilled and experienced team working on each
            project, and we have a proven track record for successfully taking
            projects from origination through to commercial operation”
          </p>
          <Button
            label="Get in touch"
            onClick={() => {
              navigate("/contact")
            }}
          />
        </div>
        <div className="imageContainer">
          <StaticImage
            src="../images/home/img-home-projects-1.png"
            className="projectImg"
            alt=""
          />
          <StaticImage
            src="../images/home/img-home-projects-2.png"
            className="projectImg"
            alt=""
          />
        </div>
      </Section>
    </Wrapper>
  )
}

export default IndexPage

const Wrapper = styled.div`
  .banner {
    h1 {
      font-size: 100px;
    }
  }

  .progress {
    padding: 140px 40px;

    .teamStatement {
      font-weight: 500;
      margin: 0 230px 36px auto;
      width: 585px;
    }

    .stats {
      display: grid;
      grid-template-columns: 1fr 1fr;

      > div {
        display: flex;
        gap: 0 30px;

        &:first-child {
          .textContainer {
            margin-top: 100px;
          }
        }

        &:last-child {
          transform: translateY(235px);

          .imageContainer {
            margin-top: 100px;
          }
        }

        .textContainer {
          .description {
            max-width: 220px;
            opacity: 0.8;
          }

          .projectType {
            margin: 0 5px 0 0;
          }

          .font-style-2 {
            margin: 0;

            > span {
              display: block;

              &.value {
                font-size: 140px;
                font-weight: 700;
                line-height: 140px;
              }
            }
          }

          .noLinkProject {
            color: #6c757d;
            font-size: 15px;
            font-weight: 500;
          }
        }

        .progressImg {
          margin: 0;

          img {
            height: auto;
            width: 285px;
          }
        }
      }
    }
  }

  .landowners {
    display: grid;
    gap: 0 95px;
    grid-template-columns: 1fr 2fr;
    padding-bottom: 225px;
    padding-top: 225px;

    h2 {
      margin-top: 0;
    }

    a span {
      font-size: 17px;
    }

    .commitments {
      display: grid;
      gap: 40px 125px;
      grid-template-columns: 1fr 1fr;
      padding-top: 8px;

      img {
        height: auto;
        margin-bottom: 10px;
        width: 54px;
      }

      h5 {
        margin: 0;
        text-transform: capitalize;
      }

      h3 {
        margin-bottom: 14px;
      }

      p {
        margin-top: 0;
        max-width: 300px;
        opacity: 0.8;
      }
    }
  }

  .projects {
    display: grid;
    grid-template-columns: 1fr 1fr;

    > div {
      display: flex;
      flex-direction: column;
      position: relative;

      &.textContainer {
        padding: 175px 0 130px 105px;

        h2 {
          margin: 0;
        }

        p {
          font-size: 18px;
          line-height: 28px;
          max-width: 375px;
          margin: 30px 0;

          @media screen and (max-width: ${(props) =>
              props.theme.breakpoints.xs}) {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }

      &.imageContainer {
        transform: translateY(-65px);

        .projectImg:first-child {
          height: auto;
          width: 350px;
        }

        .projectImg:last-child {
          height: auto;
          transform: translate(245px, -65px);
          width: 185px;
        }
      }
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xs}) {
    .banner {
      h1 {
        font-size: 56px;
      }
    }

    .progress .stats {
      padding: 0 20px;
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    .progress {
      padding: 32px 0 45px 0;

      .teamStatement {
        margin: 32px 20px 48px auto;
        width: 277px;
      }

      .stats {
        gap: 35px 0;
        grid-template-columns: 1fr;

        > div {
          &:first-child {
            justify-self: flex-start;
          }

          &:last-child {
            justify-self: flex-end;
            transform: translateY(0);

            .imageContainer {
              margin-top: 0;
            }
          }
        }
      }
    }

    .landowners {
      .commitments {
        grid-template-columns: 1fr;
      }
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.lg}) {
    .landowners {
      padding-bottom: 80px;

      .commitments {
        gap: 40px 60px;
        margin-top: 45px;
      }
    }

    .progress {
      .stats > div .textContainer .noLinkProject {
        font-size: 13px;
      }
    }

    .projects {
      grid-template-columns: 1fr;
      padding-bottom: 60px;
      padding-top: 72px;

      > div {
        &.textContainer {
          margin-bottom: 80px;
          padding: 0;

          p {
            max-width: 100%;
          }
        }

        &.imageContainer {
          transform: translateY(0);
        }
      }
    }
  }

  @media screen and (min-width: ${(props) => props.theme.minBreakpoints.md}) {
    .progress {
      .stats {
        > div:last-child {
          margin-top: 100px;
          transform: translateY(0);
        }
      }
    }
  }

  @media screen and (min-width: ${(props) =>
      props.theme.minBreakpoints.sm}) and (max-width: ${(props) =>
      props.theme.breakpoints.xl}) {
    .progress {
      padding: 80px 0;

      .teamStatement {
        margin: 0 80px 48px auto;
      }
    }
  }

  @media screen and (min-width: ${(props) =>
      props.theme.minBreakpoints.lg}) and (max-width: ${(props) =>
      props.theme.breakpoints.xl}) {
    .landowners {
      .commitments {
        gap: 40px 75px;
      }
    }

    .projects {
      > div {
        &.textContainer {
          padding: 135px 0 90px 0;
        }

        &.imageContainer {
          .projectImg:first-child {
            width: 300px;
          }

          .projectImg:last-child {
            width: 135px;
          }
        }
      }
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.xl}) {
    .progress {
      .stats {
        > div {
          flex-direction: column;
          grid-template-columns: 1fr;
          justify-self: center;

          &:first-child .textContainer,
          .textContainer {
            margin-top: 28px;
          }
        }
      }
    }

    .landowners {
      gap: 45px 0;
      grid-template-columns: 1fr;
      padding-top: 72px;
    }

    .projects {
      > div {
        &.imageContainer {
          .projectImg:first-child {
            transform: translateX(-10%);
            max-width: 350px;
            width: 70%;
          }

          .projectImg:last-child {
            transform: translate(65%, -65px);
            max-width: 185px;
            width: 40%;
          }
        }

        .gatsby-image-wrapper-constrained {
          margin: 0 auto;
        }
      }
    }
  }

  @media screen and (min-width: ${(props) =>
      props.theme.minBreakpoints.xs}) and (max-width: ${(props) =>
      props.theme.breakpoints.xxl}) {
    .progress .stats {
      padding: 0 40px;
    }
  }

  @media screen and (min-width: ${(props) =>
      props.theme.minBreakpoints.xl}) and (max-width: ${(props) =>
      props.theme.breakpoints.xxl}) {
    .projects {
      > div.textContainer {
        padding: 175px 0 130px 80px;
      }
    }
  }
`
